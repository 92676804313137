import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import HomePage from "../pages/HomePage/HomePage";
import ParlayPage from "../pages/FootballPage/ParlayPage";
import GamePage from "../pages/GamePage/GamePage";
import BodyPage from "../pages/FootballPage/BodyPage";
import EvenOddPage from "../pages/FootballPage/EvenOddPage";
import OneXTwoPage from "../pages/FootballPage/OneXTwoPage";
import CorrectScoresPage from "../pages/FootballPage/CorrectScoresPage";
import FirstHalfPage from "../pages/FootballPage/FirstHalfPage";
import BetSubmitPage from "../pages/FootballPage/BetSubmitPage";
import BetHistoryPage from "../pages/FootballPage/BetHistoryPage";
import BetHistoryDetailPage from "../pages/FootballPage/BetHistoryDetailPage";
import { useEffect, useRef, useState } from "react";
import SignIn from "../auth/SignIn/SignIn";
import Checked from "./Checked";
import IsSignIn from "./IsSignIn";
import ChangePassword from "../auth/ChangePassword/ChangePassword";
import WalletTransactionPage from "../pages/WalletPage/WalletTransactionPage";
import ProfilePage from "../pages/ProfilePage/ProfilePage";
import LiveGoalsPage from "../pages/LiveGoalsPage/LiveGoalsPage";
import LiveGoalsFavPage from "../pages/LiveGoalsFavPage/LiveGoalsFavPage";
import RuleAndRegulationsPage from "../pages/RuleAndRegulationsPage/RuleAndRegulationsPage";
import MaintenancePage from "../pages/MaintenancePage/MaintenancePage";
import { getMethodService, publicGetMethodService } from "../services";
import { useDispatch, useSelector } from "react-redux";
import { setsettings } from "../store/slices/settingsSlice";
import WalletTransactionDetailPage from "../pages/WalletPage/WalletTransactionDetailPage";
import axios from "axios";
import { SETTING_API, SETTING_JSON } from "../services/apis";
const Routers = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [intervalStatus,setIntervalStatus] = useState(true);
    const intervalRef:any = useRef(null);

    const fetchSettingJson = async () => {
        const res = await publicGetMethodService(SETTING_JSON);
        if(res){
            let obj = {
                status: res?.is_active_web,
                remark: res?.disable_reason,
                announancement: null,
            };
            dispatch(setsettings(obj));
            if(!obj?.status){
                return navigate("/maintenance");
            };
        };
    };

    const fetchSettingApi = async () => {
        const res = await publicGetMethodService(SETTING_API);
        if(res){
            if (res.status === "success" || res.status || res.success) {
                let obj = {
                    status: res?.data?.is_active_web,
                    remark: res?.data?.disable_reason,
                    announancement: res?.data?.user_announancement,
                };
                setIntervalStatus(obj?.status);
                dispatch(setsettings(obj));
                if(!obj?.status){
                    return navigate("/maintenance");
                };
            } else {
                fetchSettingJson();
            };
        };
    };

    useEffect(()=>{
        if(!intervalStatus){
            intervalRef.current = setInterval(() => {
                fetchSettingApi();
            }, 1000 * 10);
        }else{
            clearInterval(intervalRef.current);
        };
    },[intervalStatus])

    useEffect(() => {
        if(pathname === "/"){
            // Cookies.remove('filter_date');
            localStorage.removeItem("actives");
            localStorage.removeItem("bets");
        };
        window.scrollTo(0, 0);
        fetchSettingApi();
    }, [pathname]);
    return (
        <Routes>
            <Route path='/sign-in' element={
                <IsSignIn>
                    <SignIn />
                </IsSignIn>
            }/>
            <Route path='/change-password' element={
                <Checked typeName="change-password">
                    <ChangePassword />
                /</Checked>
            }/>
            <Route path='/' element={
                <Checked>
                    <HomePage />
                </Checked>
            }/>
            <Route path='/profile' element={
                <Checked>
                    <ProfilePage />
                </Checked>
            }/>
            {/* Football */}
            <Route path='/body' element={
                <Checked>
                    <BodyPage />
                </Checked>
            }/>
            <Route path='/first-half' element={
                <Checked>
                    <FirstHalfPage />
                </Checked>
            }/>
            <Route path='/even-odd' element={
                <Checked>
                    <EvenOddPage />
                </Checked>
            }/>
            <Route path='/one-x-two' element={
                <Checked>
                    <OneXTwoPage />
                </Checked>
            }/>
            <Route path='/correct-scores' element={
                <Checked>
                    <CorrectScoresPage />
                </Checked>
            }/>
            <Route path='/parlay' element={
                <Checked>
                    <ParlayPage />
                </Checked>
            }/>
            <Route path='/football/bet-confirm' element={
                <Checked>
                    <BetSubmitPage />
                </Checked>
            }/>
            <Route path='/bet-slips' element={
                <Checked>
                    <BetHistoryPage />
                </Checked>
            }/>
            <Route path='/wallet-transaction' element={
                <Checked>
                    <WalletTransactionPage />
                </Checked>
            }/>
            <Route path='/wallet-transaction/detail/:id' element={
                <Checked>
                    <WalletTransactionDetailPage />
                </Checked>
            }/>
            <Route path='/bet-slips/:id' element={
                <Checked>
                    <BetHistoryDetailPage />
                </Checked>
            }/>
            <Route path='/live-goals' element={
                <Checked>
                    <LiveGoalsPage />
                </Checked>
            }/>
            <Route path='/live-goals/favourite' element={
                <Checked>
                    <LiveGoalsFavPage />
                </Checked>
            }/>
            <Route path='/rule-regulations' element={
                <Checked>
                    <RuleAndRegulationsPage />
                </Checked>
            }/>

            <Route path='/maintenance' element={
                <Checked>
                    <MaintenancePage />
                </Checked>
            }/>

            {/* Game */}
            {/* <Route path='/games' element={<GamePage />}/> */}

            <Route path='*' element={<Navigate to="/" replace />}/>
        </Routes>
    )
}

export default Routers