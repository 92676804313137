import React, { useEffect, useState } from 'react'
import Matchs from '../Matchs/Matchs'
import { getMethodService } from '../../../services';
import { LogoutHandle, capitalizedText, decodeAuth, encodeAuth, touchSong } from '../../../utils/Helper';
import { useNavigate } from 'react-router-dom';
import {io} from "socket.io-client";
import { createPortal } from 'react-dom';
import Loader from '../../../utils/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { leaguesHandler } from '../../../store/slices/leaguesSlice';
import NotFound from '../../../utils/NotFound';
import { GET_FIXTURES_DATA_SERVICE } from '../../../services/graphql/queryService';
import { GET_FIXTURES_DATA } from '../../../services/graphql/queries';
import { setBetLists } from '../../../store/slices/betListsSlice';
import MatchsParlay from '../Matchs/MatchsParlay';
import { message } from 'antd';
import { callStatusHandler } from '../../../store/slices/callMatchsApiSlice';
const Cookies = require('js-cookie');

let betLists:any = [];
const Parlay = () => {
  // const fixturesQuery:any = GET_FIXTURES_DATA_SERVICE(GET_FIXTURES_DATA);
  const [isLoading,setIsLoading] = useState(false);
  const [fixtures,setFixtures] = useState([]);
  const [sortedFixtures,setSortedFixtures] = useState([]);
  const [leagueNames,setLeagueNames] = useState<any>([]);
  const [totalMatch,setTotalMatch] = useState("-");
  const [newOddsFt,setNewOddsFt] = useState<any>({});
  const [newOddsFh,setNewOddsFh] = useState<any>({});
  const [searchValue,setSearchValue] = useState([]);
  const [filterByLeagues,setFilterByLeagues] = useState<any>([]);
  const [activeNames,setActiveNames] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {filter} = useSelector((state:any) => state.filterSetting);
  const {refresh} = useSelector((state:any) => state.refresh);
  const {search} = useSelector((state:any) => state.marketOdds);
  const [messageApi, contextHolder] = message.useMessage();
  const {sortByType} = useSelector((state:any) => state.sortType);

  GET_FIXTURES_DATA_SERVICE(GET_FIXTURES_DATA)?.then((fixturesQuery:any) => {
    setIsLoading(fixturesQuery?.loading);
    setFixtures(fixturesQuery?.matches);
  })
  .catch((error:any) => console.error(error));

  useEffect(()=>{
    setIsLoading(true);
  },[refresh]);

  const alertMessage = (status:any, msg:any) => {
    messageApi.open({
      key: 'betMessage',
      type: status,
      content: msg,
    });
  };

  useEffect(()=>{
    betLists = [];
    dispatch(setBetLists(null));
    dispatch(callStatusHandler(false));
  },[]);

  // useEffect(()=>{
  //   fixturesQuery?.refetch();
  //   setIsLoading(true);
  // },[refresh]);

  // useEffect(() => {
  //   setIsLoading(fixturesQuery?.loading);
  //   if (fixturesQuery?.error?.message === "Not authenticated" || fixturesQuery?.error?.message.includes("Invalid token") || fixturesQuery?.error?.message.includes("Unauthorized token") || fixturesQuery?.error?.message.includes("Not Authorised!")) {
  //     return LogoutHandle(navigate);
  //   };
  //   if (fixturesQuery?.data) {
  //     if (fixturesQuery.data.matches) {
  //       setFixtures(fixturesQuery.data.matches);
  //     } else {
  //       setFixtures([]);
  //     };
  //   };
  // }, [fixturesQuery]);

  useEffect(()=>{
    let url:any= process.env.REACT_APP_SOCCER_DOMAIN;
    const socket = io(url,{
        transports: ["websocket"],
        path: '/api/soccer/socket',
    });
    socket.connect();
    socket.on('connect', () => {
        console.log("Soc Suc!");
        // setOddsStatus(true);
    });
    socket.on('disconnect', () =>  {
        console.log("Soc Dec!");
        // setOddsStatus(false);
    });
    socket.on("ft_odds_updated_data", res => {
      // console.log("tf", res);
      setNewOddsFt(res);
    });
    socket.on("fh_odds_updated_data", res => {
      // console.log("th", res);
      setNewOddsFh(res);
    });
    socket.on("hello", res => {
      console.log("hello received", res);
        // setNewOdds(res);
    });
    return () => {
        socket.disconnect();
    };
  },[]);

  useEffect(()=>{
    let parlayMatchsArr:any = [];
    let parlayLeagueNamesArr:any = [];
    let allLeaguesNamesArr:any = [];

    if(search){
      if(searchValue?.length > 0 && typeof searchValue !== "string"){
          searchValue?.map((fixture:any) => {
            if (fixture?.id == newOddsFt?.match_id) {
              fixture = {
                ...fixture,
                odds: {
                  ...fixture.odds,
                  full_time: {
                    ...fixture.odds.full_time,
                    hdp_mm_odds: newOddsFt?.data?.hdp_mm_odds || fixture?.odds.full_time?.hdp_mm_odds,
                    ou_mm_odds: newOddsFt?.data?.ou_mm_odds || fixture?.odds.full_time?.ou_mm_odds,
                  },
                },
              };
            }
            if (fixture?.id == newOddsFh?.match_id) {
              fixture = {
                ...fixture,
                odds: {
                  ...fixture.odds,
                  first_half: {
                    ...fixture.odds.first_half,
                    hdp_mm_odds: newOddsFh?.data?.hdp_mm_odds || fixture?.odds?.first_half?.hdp_mm_odds,
                    ou_mm_odds: newOddsFh?.data?.ou_mm_odds || fixture?.odds?.first_half?.ou_mm_odds,
                  },
                },
              };
            };
            if(fixture?.is_perlay){
                parlayMatchsArr.push(fixture);
            };
        });
      };
    }else{
      if(fixtures?.length > 0 && typeof fixtures !== "string"){
          fixtures?.map((fixture:any) => {
            if (fixture?.id == newOddsFt?.match_id) {
              fixture = {
                ...fixture,
                odds: {
                  ...fixture.odds,
                  full_time: {
                    ...fixture.odds.full_time,
                    hdp_mm_odds: newOddsFt?.data?.hdp_mm_odds || fixture?.odds.full_time?.hdp_mm_odds,
                    ou_mm_odds: newOddsFt?.data?.ou_mm_odds || fixture?.odds.full_time?.ou_mm_odds,
                  },
                },
              };
            };
            if (fixture?.id == newOddsFh?.match_id) {
              fixture = {
                ...fixture,
                odds: {
                  ...fixture.odds,
                  first_half: {
                    ...fixture.odds.first_half,
                    hdp_mm_odds: newOddsFh?.data?.hdp_mm_odds || fixture?.odds?.first_half?.hdp_mm_odds,
                    ou_mm_odds: newOddsFh?.data?.ou_mm_odds || fixture?.odds?.first_half?.ou_mm_odds,
                  },
                },
              };
            };

            if(fixture?.is_perlay){
              parlayMatchsArr.push(fixture);
            };
        });
      };
    };
    // sorting with timeStemp
    if(parlayMatchsArr.length > 0){
        parlayMatchsArr.map((match:any) => {
          parlayLeagueNamesArr.push(match?.league_data?.name_en);
          allLeaguesNamesArr.push(match?.league_data?.name_en);
        });
    };

    let bigLeagueName = new Set(parlayLeagueNamesArr);
    let uniqueBigLeagueName = Array.from(bigLeagueName);

    let allLeagueName = new Set(allLeaguesNamesArr);
    let uniqueAllLeagueName:any = Array.from(allLeagueName);
    uniqueAllLeagueName.sort((a:any, b:any) => {
        const leagueNameA = a.toLowerCase();
        const leagueNameB = b.toLowerCase();
        if (leagueNameA < leagueNameB) {
          return -1;
        }
        if (leagueNameA > leagueNameB) {
          return 1;
        }
        return 0;
    });
    setLeagueNames(uniqueAllLeagueName);

    let bigLeagueObj = uniqueBigLeagueName.map(league => {return {league_name: league, timestamp: '', matchs : []}});

    if(parlayMatchsArr.length > 0){
        parlayMatchsArr.map((match:any) => {
          let findSameLeagueName:any = bigLeagueObj.find(lea => lea.league_name === match.league_data?.name_en);
          if(findSameLeagueName){
              if(findSameLeagueName.timestamp === match.fixture_timestamp){
                  findSameLeagueName.matchs.push(match);
              }else{
                  findSameLeagueName.league_name = match?.league_data?.name_en;
                  findSameLeagueName.timestamp = match.fixture_timestamp;
                  findSameLeagueName.matchs.push(match);
              };
          };
        })
    };

    // ----------------- Same League but different Fixture start Time -----------------
      let bigSameLeagueDiffTimeArr:any = [];

      if(bigLeagueObj.length > 0){
        bigLeagueObj.map((match:any,i) => {
           if(match?.matchs?.length > 1){
            const allSameTime = match?.matchs.every((value:any, index:any, array:any) => value?.fixture_timestamp === array[0]?.fixture_timestamp);
            if(allSameTime){
              bigSameLeagueDiffTimeArr.push(match);
            }else{
                const groupedMatches = match.matchs.reduce((result:any, match:any) => {
                  const index = result.findIndex((group:any) => group.timestamp === match.fixture_timestamp);
                  if (index !== -1) {
                    result[index].matchs.push(match);
                  } else {
                    result.push({
                      league_name: match?.league_data?.name_en,
                      matchs: [match],
                      timestamp: match.fixture_timestamp
                    });
                  };
                  return result;
                }, []);
                bigSameLeagueDiffTimeArr.push(...groupedMatches);
            };
           }else{
            bigSameLeagueDiffTimeArr.push(match);
           }
        })
      };
      
        if(sortByType === 'time'){
          let filteredArr1:any = [];

          filterByLeagues.forEach((league:any) => {
            const filteredValues = bigSameLeagueDiffTimeArr.filter((obj:any) => obj.league_name === league);
            filteredArr1.push(...filteredValues);
          });
          filteredArr1.sort((a:any,b:any) => Number(a?.timestamp) - Number(b?.timestamp));
          let sortdTime:any = [...filteredArr1];
          setSortedFixtures(sortdTime);
        }else{
          let filteredArr1:any = [];

          filterByLeagues.forEach((league:any) => {
            const filteredValues = bigSameLeagueDiffTimeArr.filter((obj:any) => obj.league_name === league);
            filteredArr1.push(...filteredValues);
          });

          let finalSortArr:any = [...filteredArr1];
          finalSortArr.sort((a:any, b:any) => {
            const leagueNameA = a.league_name.toLowerCase();
            const leagueNameB = b.league_name.toLowerCase();
            if (leagueNameA < leagueNameB) {
              return -1;
            }
            if (leagueNameA > leagueNameB) {
              return 1;
            }
            return 0;
          });
          setSortedFixtures(finalSortArr);
        };
  },[searchValue,fixtures,newOddsFt,newOddsFh,sortByType,filterByLeagues]);

  useEffect(()=>{
    if(filter?.checkAll){
      if(filter?.leagues?.length === 0){
        dispatch(leaguesHandler(leagueNames));
      };
    };

    if(filter?.leagues?.length > 0){
      setFilterByLeagues(filter?.leagues);
    }else{
        if(!filter?.checkAll){
          setFilterByLeagues(filter?.leagues);
        };
    };
  },[leagueNames,filter]);

  useEffect(()=>{
    const capitalized = capitalizedText(search);
    let find = fixtures?.filter((result:any) => 
      result?.fixture_start_time?.includes(search) || 
      result?.league_data?.name_en?.includes(capitalized) ||
      result?.host_team_data?.name_en?.includes(capitalized) ||
      result?.host_team_data?.name_mm?.includes(capitalized) ||
      result?.guest_team_data?.name_en?.includes(capitalized) ||
      result?.guest_team_data?.name_mm?.includes(capitalized)
    );
    setSearchValue(find);
  },[search]);

  const pressHandler = (activeType:any,fixtureList:any,type:any,market:any,is_first_half:any = false,correctScores_odds:any) => {
    touchSong();
    let cs_odds = correctScores_odds? {cs_odds: correctScores_odds}: {};
    let data = {
        fixture: fixtureList,
        match_id: fixtureList?.id,
        market,
        team: type,
        match_stage: is_first_half ? 'first_half': 'full_time',
        ...cs_odds
    };
    let isFirstHalf = is_first_half ? 'first_half': 'full_time';
    
    let findSameFixture = betLists.findIndex((list:any) => list.fixture.id === fixtureList.id);
    if(findSameFixture !== -1){
      if(betLists[findSameFixture].team === type && betLists[findSameFixture].match_stage === isFirstHalf){
          betLists.splice(findSameFixture, 1);
      }else{
        betLists.splice(findSameFixture, 1);
        betLists.push(data);
      };
    }else{
      if(betLists?.length === 11){
        return alertMessage('error','အများဆုံး ၁၁ မောင်းသာလောင်းပါ။');
      }else{
        betLists.push(data);
      };
    };
    let activeName = betLists.map((list:any) => `${list.fixture.id}_${list.team}_${list.match_stage}`)
    setActiveNames([...activeName]);
    betLists.sort((a:any,b:any) => Number(a?.fixture?.fixture_timestamp) - Number(b?.fixture?.fixture_timestamp));
    localStorage.setItem("actives",encodeAuth([...activeName]));
    localStorage.setItem("bets",encodeAuth([...betLists]));
    dispatch(setBetLists([...betLists]));
  };

  useEffect(()=>{
    let getTeamNames = localStorage.getItem("actives");
    let getBetLists = localStorage.getItem("bets");

    if(getTeamNames){
      setActiveNames(decodeAuth(getTeamNames));
    };
    if(getBetLists){
      betLists = decodeAuth(getBetLists);
      dispatch(setBetLists([...betLists]));
    };
  },[])

  // console.log(sortedFixtures)

  return (
    <>
      {contextHolder}
      <div style={{height: '100%'}}>
        {
          sortedFixtures?.length > 0 ?
          <MatchsParlay selectActive={activeNames} data={sortedFixtures} pressHandler={pressHandler}  changeFtOdds={null} changeFhOdds={null} />:  //changeFtOdds={newOddsFt} changeFhOdds={newOddsFh}
          !isLoading && <NotFound text="ပွဲစဉ်မရှိပါ" />
        }
      </div>
      {
          createPortal(isLoading && <Loader />, document.getElementById('loading') as HTMLElement)
      }
    </>
  )
}

export default Parlay
