import React, { useEffect, useRef, useState } from 'react';
import classes from './Header.module.css';
import { HeaderTypes } from '../../types';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { LogoutHandle, decodeAuth, encodeAuth, getTodayDate, htmlToPng, touchSong } from '../../utils/Helper';
import { MdArrowBackIos } from 'react-icons/md';
import { FaSignOutAlt, FaUser } from "react-icons/fa";
import { Button, DatePicker,Dropdown, message } from 'antd';
import { HiOutlineDownload } from "react-icons/hi";
import { GET_USER_INFO_SERVICE } from '../../services/graphql/queryService';
import { GET_USER_INFO_DATA } from '../../services/graphql/queries';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRange } from '../../store/slices/dateRangeSlice';
import moment from 'moment';
import { setUserInfoHandler } from '../../store/slices/userInfoSlice';
import PageTransition from '../PageTransition';
import { IoArrowBackOutline } from 'react-icons/io5';
import Search from 'antd/es/transfer/search';
import { searchFilterHandler } from '../../store/slices/marketOddsSlice';
import { FiSearch } from 'react-icons/fi';
import { postMethodService } from '../../services';
import { LOGOUT_API } from '../../services/apis';
import Range from '../../utils/Range';
import { langChange } from '../../lang';
import { createPortal } from 'react-dom';
import Loader from '../../utils/Loader/Loader';
import { callStatusHandler } from '../../store/slices/callMatchsApiSlice';
const logo = require('../../assets/images/logo/logo.png');
const { RangePicker } = DatePicker;
const Cookies = require('js-cookie');

const Header = ({home,title,showDate,download,showWallet,search}:HeaderTypes) => {
  const [messageApi, contextHolder] = message.useMessage();
  const currentDate = moment().format("YYYY-MM-DD");
  const userInfoQuery:any = (home || !showDate) && GET_USER_INFO_SERVICE(GET_USER_INFO_DATA);
  const [userInfo,setUserInfo] = useState<any>({});
  const [searchBox,setSearchBox] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const showDateStatus = pathname === "/";
  const refetchStatus = useSelector((state:any) => state.refreshBalance.refreshStatus);
  const getDates = localStorage.getItem("hist_date");
  const decoded = decodeAuth(getDates);
  const [startDate, setStartDate] = useState(decoded?.start_date || currentDate);
  const [endDate, setEndDate] = useState(decoded?.end_date || currentDate);
  const [isLoading,setIsLoading] = useState(false);

  const alertMessage = (status:any, msg:any) => {
		messageApi.open({
		  type: status,
		  content: msg,
		});
	};

  useEffect(()=>{
    const today = getTodayDate(currentDate, currentDate);
    let dateRangeObj = {
        start_date: today.start_date,
        end_date: today.end_date
    };
    if(pathname === '/'){
      dispatch(setDateRange(dateRangeObj));
    };
  },[pathname])

  useEffect(()=>{
      if(userInfoQuery){
        userInfoQuery?.refetch();
      }; 
  },[refetchStatus]);

  useEffect(()=>{
    if(userInfoQuery?.error?.graphQLErrors[0]?.extensions?.code === "DOWNSTREAM_SERVICE_ERROR"){
			return LogoutHandle(navigate);
		};

    if (userInfoQuery?.error?.message === "Not authenticated" || userInfoQuery?.error?.message.includes("Invalid token") || userInfoQuery?.error?.message.includes("Unauthorized token") || userInfoQuery?.error?.message.includes("Not Authorised!")) {
      return LogoutHandle(navigate);
    };
    if (userInfoQuery?.data) {
      if (userInfoQuery?.data?.auth) {
        dispatch(setUserInfoHandler(userInfoQuery?.data?.auth));
        setUserInfo(userInfoQuery?.data?.auth);
      };
    };
  },[userInfoQuery])

  const backHandler = () => {
    if(pathname === "/football/bet-confirm"){
      dispatch(callStatusHandler(false));
    };
    navigate(-1);
    touchSong();
  };


  const downloadHandler = () => {
      let saveDom:any = document.getElementById("saveToImg");
      // let images = saveDom.getElementsByTagName("img");
      // while(images.length > 0){
      //     images[0].parentNode.removeChild(images[0]);
      // };
      return htmlToPng(saveDom, `3XScores_Slip${params?.id}`);
  };

  const handleSignOut = async () => {
    setIsLoading(true);
		let res = await postMethodService(LOGOUT_API);
		if(res){
      setIsLoading(false);
			if(res === "Unauthenticated"){
				return LogoutHandle(navigate);
			};
			if(res.status === "success" || res.status || res.success){
				alertMessage("success","အောင်မြင်ပါသည်။");
				setTimeout(()=> LogoutHandle(navigate), 600);
			}else{
				alertMessage("error",res);
			};
		};
	};

  const items: any = [
    {
      label: <span style={{fontSize: '12px',color: 'var(--accent-color)'}} onClick={() => navigate('/profile')}>{userInfo?.usercode || "Profile"}</span>,
      key: '0',
      icon: <FaUser size={15} color='var(--accent-color)' />,
    },
    {
      type: 'divider',
    },
    {
      label: <span style={{fontSize: '12px',color: 'var(--accent-color)',display: 'block'}} onClick={handleSignOut}>{langChange.signout}</span>,
      key: '1',
      icon: <FaSignOutAlt size={15} color='var(--accent-color)'/>,
    },
  ];

  // const dateHandler = (value:any,dateString:any) => {
  //   const dateHandle = getTodayDate(dateString,dateString);
  //   let dateRangeObj = {
  //       start_date: dateHandle?.start_date,
  //       end_date: dateHandle?.end_date,
  //   };
  //   console.log(dateRangeObj)
  //   dispatch(setDateRange(dateRangeObj));
  // };

  const backAllMatchsHandler = () => {
    setSearchBox(false);
    dispatch(searchFilterHandler(""));
  };

  const searchHandler = (e:any) => {
    dispatch(searchFilterHandler(e.target.value));
  };

  useEffect(()=>{
      const dateHandle = getTodayDate(startDate,endDate);
      let dateRangeObj = {
          start_date: dateHandle?.start_date,
          end_date: dateHandle?.end_date,
      };
      const saveDates = {
        start_date: startDate,
        end_date: endDate,
      }
      const encoded = encodeAuth(saveDates);
      localStorage.setItem("hist_date", encoded);
      dispatch(setDateRange(dateRangeObj));
  },[startDate,endDate]);

  useEffect(()=>{
    if(showDateStatus){
      localStorage.removeItem("hist_date");
    };
 },[showDateStatus]);

  return (
    <>
      {contextHolder}
      <header className={classes.header}>
          {
            home? 
            <div className={classes.logoContainer}>
               <Link to='/'>
                <img src={logo} />
               </Link>
               <div className={classes.userInfo}>
                  <div>
                    <p> 
                      {
                        userInfo?.balance?.main_balance ?
                        Number(userInfo?.balance?.main_balance)?.toLocaleString('en-US'):
                        '-'
                      }
                    </p>
                  </div>
                  <Dropdown placement="bottomRight" menu={{ items }} trigger={['click']}>
                    <span><FaUser size={15} style={{color: 'var(--accent-color)'}}/></span>
                  </Dropdown>
               </div>
            </div>
            :
            (
              showDate?
              <div style={{height: '100%',width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <button className={classes.back} onClick={backHandler}>
                    <MdArrowBackIos size="25px"/>
                </button>
                {/* <span className={classes.typeName}>{`${title} ${showDateStatus? ` ( ${moment(start_date?.split(" ")[0]).format("DD MMMM")} )`: ''}`}</span> */}
                <span className={classes.typeName}>{title}</span>
                <div className="date-picker-container" style={{cursor: 'pointer'}} onClick={touchSong}>
                  {
                    download? 
                    <Button style={{backgroundColor: 'var(--accent-color)'}} type="primary" shape="circle" icon={<HiOutlineDownload size={22} />} size='large' onClick={downloadHandler}/>
                    :
                    // <DatePicker
                    //   suffixIcon={null}
                    //   placement='topRight'
                    //   onChange={dateHandler}
                    // />
                    <Range startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
                    
                  }
                </div>
              </div>
              :
                (searchBox ?
                <PageTransition>
                  <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                      <button className={classes.backNormal} onClick={backAllMatchsHandler} >
                        <IoArrowBackOutline size="25px"/>
                      </button>
                      <Search placeholder="Search..." onChange={searchHandler} />
                  </div>
                </PageTransition>:
                <PageTransition>
                  <div style={{height: '100%',width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <button className={classes.back} onClick={backHandler}>
                        <MdArrowBackIos size="25px"/>
                    </button>
                    <span className={classes.typeName}>{title}</span>
                    {
                      showWallet?
                      <p style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end',marginBottom: '0'}}>
                        <span style={{fontSize: '15px', color: 'var(--accent-color)'}}>{langChange.balance}</span> 
                        <span style={{fontSize: '15px', margin: '0 10px'}}>-</span> 
                        <span style={{fontSize: '15px', color: 'var(--accent-color)', fontWeight: 'bolder'}}>{Number(userInfo?.balance?.main_balance)?.toLocaleString('en-US') || '--'}</span>
                      </p> :
                      (
                        search ? 
                        <button className={classes.headerIconBtn} onClick={() => setSearchBox(true)}>
                              <FiSearch size="23px" />
                        </button>
                          :
                        <p style={{width: '40px'}}></p>
                      )
                    }
                  </div>
                </PageTransition>
              )
            )
          }
      </header>
      {createPortal(
        isLoading && <Loader />,
        document.getElementById("loading") as HTMLElement
      )}
    </>
  )
}

export default Header
