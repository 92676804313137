import React, { useEffect, useRef, useState } from 'react';
import classes from './FootballFooter.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { decodeAuth, LogoutHandle, touchSong } from '../../utils/Helper';
import { Button, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { setBetLists } from '../../store/slices/betListsSlice';
import { GET_USER_INFO_SERVICE } from '../../services/graphql/queryService';
import { GET_USER_INFO_DATA } from '../../services/graphql/queries';
import { langChange } from '../../lang';
import { focusHandler } from '../../store/slices/removeInputFocusSlice';

const FootballFooter = ({title}:any) => {
  const userInfoQuery = GET_USER_INFO_SERVICE(GET_USER_INFO_DATA);
  const [amount,setAmount] = useState("");
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {betLists} = useSelector((state:any) => state.betLists);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const [userInfo,setUserInfo] = useState<any>({});
  const refetchStatus = useSelector((state:any) => state.refreshBalance.refreshStatus);
  const inputRef:any = useRef();
  const {isFocus} = useSelector((state:any) => state.focusInput);

  const alertMessage = (status:any, msg:any) => {
    messageApi.open({
      key: 'errorMessage',
      type: status,
      content: msg,
    });
  };

  useEffect(()=>{
      if(userInfoQuery){
        userInfoQuery?.refetch();
      }; 
  },[refetchStatus]);

  useEffect(()=>{
    if(userInfoQuery?.error?.graphQLErrors[0]?.extensions?.code === "DOWNSTREAM_SERVICE_ERROR"){
			return LogoutHandle(navigate);
		};
    if (userInfoQuery?.error?.message === "Not authenticated" || userInfoQuery?.error?.message.includes("Invalid token") || userInfoQuery?.error?.message.includes("Unauthorized token") || userInfoQuery?.error?.message.includes("Not Authorised!")) {
      return LogoutHandle(navigate);
    };
    if (userInfoQuery?.data) {
      if (userInfoQuery?.data?.auth) {
        setUserInfo(userInfoQuery?.data?.auth);
      };
    };
  },[userInfoQuery]);
  
  const betHandler = async () => {
    touchSong();
    if(betLists){
      if(title === "မောင်း"){
        if(betLists.length < 2){
          return alertMessage('error','အနည်းဆုံး ၂ မောင်းလောင်းပါ။');
        };
      };
      if(amount){
        // if(Number(amount) >= 500){
          if(betLists.length < 12){
            dispatch(setBetLists({matches: betLists,amount}));
            navigate('/football/bet-confirm')
          }else{
            alertMessage('error','အများဆုံး ၁၁ မောင်းသာလောင်းပါ။');
          };
        // }else{
        //   alertMessage('error','အနည်းဆုံး 500 ထည့်ပါ။');
        // }
      }else{
        alertMessage('error', 'လောင်းကြေးထည့်ပါ');
      };
    }else{
      alertMessage('error', 'ကစားပွဲရွေးပါ');
    };
  };

  useEffect(()=>{
    if(!isFocus){
      inputRef?.current?.blur();
    };
  },[inputRef,isFocus]);

  const swipeHandler = () => {
    dispatch(focusHandler(false));
  };
 
  return (
    <>
      {contextHolder}
      <footer className={classes.footer}
      onTouchStart={swipeHandler}
      onTouchEnd={swipeHandler}
      >
            <div style={{height: '100%'}}>
              <div className={classes.betAmount}>
                <p style={{fontSize: '13px', minWidth:  title === langChange.parlay ? '100px': '65px',maxWidth: title === "မောင်း"? '100px':'65px'}}>{langChange.bet_amount} {title === langChange.parlay && `| ( ${betLists?.length || 0} )`}</p>
                <Input autoFocus={isFocus} onFocus={() => dispatch(focusHandler(true))} type='tel' ref={inputRef} style={{width: '100%', margin: '0 10px', fontSize: '13px', padding: '9px', height: '32px'}} placeholder="1000" size='large' onChange={(e) => setAmount(e.target.value)}/>
                <Button style={{backgroundColor: 'var(--accent-color)',fontSize: '13px', padding: ' 0 10px', height: '32px'}} type="primary" size='large' onClick={betHandler}>{langChange.bet_confirm}</Button>
              </div>
              <p style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '3px'}}>
                <span style={{fontSize: '13px', color: '#FFF'}}>{langChange.balance}</span> 
                <span style={{fontSize: '13px', color: '#FFF', margin: '0 10px'}}>-</span> 
                <span style={{fontSize: '13px', color: '#FFF', fontWeight: 'bolder'}}>
                    {
                      userInfo?.balance?.main_balance? 
                      Number(userInfo?.balance?.main_balance || 0)?.toLocaleString('en-US'):
                      '-'
                    }
                </span>
              </p>
            </div>
      </footer>
    </>
  )
}

export default FootballFooter
